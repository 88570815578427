export default {
  name: 'vitalSignsMixin',
  data: () => ({
    vitalSigns: [
      {
        question: 'Date of Measurement',
        data: null
      },
      {
        question: 'Height',
        data: null
      },
      {
        question: 'Weight',
        data: null
      },
      {
        question: 'Systolic Blood Pressure ',
        data: null,
      },
      {
        question: 'Diastolic Blood Pressure ',
        data: null,
      },
      {
        question: 'Heart Rate',
        data: null,
      }
    ]
  }),
  methods: {
    assignVitalSigns(data) {
      this.vitalSigns[0].data = this.formatDateAbvMonth(data.dateOfVitalSigns);
      this.vitalSigns[1].data = data.heightInCM;
      this.vitalSigns[2].data = data.weightInKG;
      this.vitalSigns[3].data = data.systolicBloodPressure;
      this.vitalSigns[4].data = data.diastolicBloodPressure;
      this.vitalSigns[5].data = data.heartRate;
    }
  }
};
