import { $toNormalCase } from "@/helper/globalFunction";

export default {
  name: 'sixMinuteWalkTestMixin',
  data: () => ({
    sixMinuteWalkTest: [
      {
        question: 'Date of 6MWT',
        data: null
      },
      {
        question: 'Start Time of 6MWT',
        data: null
      },
      {
        question: 'End Time of 6MWT',
        data: null
      },
      {
        question: 'Duration of test',
        data: null,
      },
      {
        question: 'Stopped or paused before 6 minutes',
        data: null,
      },
      {
        question: 'Assistive Walking Devices and Orthoses',
        data: null,
      },
      {
        question: 'Was the patient on respiratory support (e.g.,ventilation, CPAP) during the test',
        data: null,
      },
      {
        question: 'Pre-test blood pressure (systolic/diastolic)',
        data: null,
      },
      {
        question: 'Pre-test heart rate',
        data: null,
      },
      {
        question: 'Post-test heart rate',
        data: null,
      },
      {
        question: 'Pre-test dyspnoe (Borg Scale)',
        data: null,
      },
      {
        question: 'Post-test dyspnoe (Borg Scale)',
        data: null,
      },
      {
        question: 'Pre-test fatigue (Borg Scale)',
        data: null,
      },
      {
        question: 'Post-test fatigue (Borg Scale)',
        data: null,
      },
      {
        question: 'Other symptoms at end of exercise',
        data: null,
      },
      {
        question: 'Total distance walked',
        data: null
      },
      {
        question: "Type of Respiratory Support",
        data: null,
      }
    ]
  }),
  methods: {
    $toNormalCase,
    assignSixMinuteWalkTest(data) {
      this.sixMinuteWalkTest[0].data = this.formatDateAbvMonth(data.dateOf6MWT);
      this.sixMinuteWalkTest[1].data = `${data.startTime6MWTHour}:${data.startTime6MWTMinute}`;
      this.sixMinuteWalkTest[2].data = `${data.endTime6MWTHour}:${data.endTime6MWTMinute}`;
      this.sixMinuteWalkTest[3].data = this.$toNormalCase(data.durationTest);
      this.sixMinuteWalkTest[4].data = this.$toNormalCase(data.stoppedBefore6Minutes);
      this.sixMinuteWalkTest[5].data = this.$toNormalCase(data.assistiveWalkingDevices);
      this.sixMinuteWalkTest[6].data = this.$toNormalCase(data.respiratorySupportGiven);
      this.sixMinuteWalkTest[7].data = `${data.pretestBloodPressureSystolic}/${data.pretestBloodPressureDiastolic}`;
      this.sixMinuteWalkTest[8].data = data.pretestHeartRate;
      this.sixMinuteWalkTest[9].data = data.posttestHeartRate;
      this.sixMinuteWalkTest[10].data = data.pretestDyspnoe;
      this.sixMinuteWalkTest[11].data = data.posttestDyspnoe;
      this.sixMinuteWalkTest[12].data = data.pretestFatigue;
      this.sixMinuteWalkTest[13].data = data.posttestFatigue;
      this.sixMinuteWalkTest[14].data = this.$toNormalCase(data.otherSymptomsEndExercise);
      this.sixMinuteWalkTest[15].data = data.totalDistanceWalked;
      this.sixMinuteWalkTest[16].data = this.$toNormalCase(data.typeRespiratorySupport);
    }
  }
};
