import { $toNormalCase } from "@/helper/globalFunction";
export default {
  name: 'muscoskeletalMotorMixin',
  data: () => ({
    motorFunctional: [
      {
        question: 'Exam Date',
        data: null
      },
      {
        question: 'Walking 10 meters',
        data: null
      },
      {
        question: 'Assistance',
        data: null
      }
    ]
  }),
  methods: {
    $toNormalCase,
    assignMotorFunctional(data) {
      this.motorFunctional[0].data = 
        this.formatDateAbvMonth(data.dateOfMotorFunctionalActivities);
      this.motorFunctional[1].data = data.walking10Meters;
      this.motorFunctional[2].data = this.$toNormalCase(data.walking10MetersAssistance);
    }
  }
}