<template>
  <div id="assessment-pdf-template">
    <div class="flex mt-3">
      <div class="left">
        <h4 class="mt-2 text-muted">PDF Preview</h4>
      </div>
      <div class="right">
        <button class="btn btn-danger btn-sm" @click="exportToPDF">
          Generate PDF
        </button>
      </div>
    </div>

    <hr />

    <div ref="document" class="container">
      <h6 v-if="idx === 0">Swiss Pompe Registry</h6>

      <hr />

      <div class="section">
        <h4>1. Patient</h4>
        <pompe-table
          v-bind="{
            showPagination: false,
            data: content.pdfPage.patients,
            columns: options.patients.columns,
          }"
        />
      </div>

      <br />

      <div class="section">
        <h4>2. Assessment</h4>
        <pompe-table
          v-bind="{
            showPagination: false,
            data: content.pdfPage.assessment,
            columns: options.assessment.columns,
          }"
        />
      </div>

      <br />

      <div class="section">
        <h4>2.1 Six Minute Walk Test</h4>
        <pompe-table
          v-bind="{
            showPagination: false,
            data: sixMinuteWalkTest,
            columns: options.sixMinuteWalkTest.columns,
          }"
        />
      </div>

      <div class="section">
        <h6 class="font-weight-light scale-title">Borg Scale</h6>
        <pompe-table
          v-bind="{
            showPagination: false,
            data: options.sixMinuteWalkTest.scaleValues,
            columns: options.sixMinuteWalkTest.scaleColumn,
          }"
        />
      </div>

      <br />

      <div class="section">
        <h4>2.2 Medical Research Council Scale</h4>
        <pompe-table
          v-bind="{
            showPagination: false,
            data: mRCScale,
            columns: options.medicalResearchCouncilScale.columns,
          }"
        />
      </div>

      <div class="section">
        <h6 class="font-weight-light scale-title">MRC Scale</h6>
        <pompe-table
          v-bind="{
            showPagination: false,
            data: options.medicalResearchCouncilScale.scaleValues,
            columns: options.medicalResearchCouncilScale.scaleColumn,
          }"
        />
      </div>

      <br />

      <div class="section">
        <h4>2.3 Pulmonary Function Test</h4>
        <pompe-table
          v-bind="{
            showPagination: false,
            data: pulmonaryFunctionTest,
            columns: options.pulmonaryFunctionTest.columns,
          }"
        />
      </div>

      <br />

      <div class="section">
        <h4>2.4 Motor Functional Activities</h4>
        <pompe-table
          v-bind="{
            showPagination: false,
            data: motorFunctional,
            columns: options.motorFunctionalActivities.columns,
          }"
        />
      </div>

      <br />

      <div class="section">
        <h4>2.5 Respiratory Assessment</h4>
        <pompe-table
          v-bind="{
            showPagination: false,
            data: respiratoryAssessment,
            columns: options.respiratoryAssessment.columns,
          }"
        />
      </div>

      <br />

      <div class="section">
        <h4>2.6 Muscoskeletal / Motor Assessment</h4>
        <pompe-table
          v-bind="{
            showPagination: false,
            data: muscoskeletalMotor,
            columns: options.muscoskeletalMotorAssessment.columns,
          }"
        />
      </div>

      <br />

      <div class="section">
        <h4>2.7 Modified Ranking Scale</h4>
        <pompe-table
          v-bind="{
            showPagination: false,
            data: modifiedRankingScale,
            columns: options.modifiedRankingScale.columns,
          }"
        />
      </div>

      <div class="section">
        <h6 class="font-weight-light scale-title">Modified Ranking Scale</h6>
        <pompe-table
          v-bind="{
            showPagination: false,
            data: options.modifiedRankingScale.scaleValues,
            columns: options.modifiedRankingScale.scaleColumn,
          }"
        />
      </div>

      <br />

      <div class="section">
        <h4>2.8 Rotterdam 9-Item Handicap Scale</h4>
        <pompe-table
          v-bind="{
            showPagination: false,
            data: rotterdamScale,
            columns: options.rotterdam9Scale.columns,
          }"
        />
      </div>

      <br />

      <div class="section">
        <h4>2.9 Fatigue Severity Scale</h4>
        <pompe-table
          v-bind="{
            showPagination: false,
            data: fatigueSeverityScale,
            columns: options.fatigueSeverityScale.columns,
          }"
        />
      </div>

      <div class="section">
        <h6 class="font-weight-light scale-title">Fatigue Severity Scale</h6>
        <pompe-table
          v-bind="{
            showPagination: false,
            data: options.fatigueSeverityScale.scaleValues,
            columns: options.fatigueSeverityScale.scaleColumn,
          }"
        />
      </div>

      <br />

      <div class="section">
        <h4>2.10 Walton & Gardner Melwin Scale</h4>
        <pompe-table
          v-bind="{
            showPagination: false,
            data: waltonGardner,
            columns: options.waltonGardnerMelwinScale.columns,
          }"
        />
      </div>

      <div class="section">
        <h6 class="font-weight-light scale-title">
          Walton & Gardner Melwin Scale
        </h6>
        <pompe-table
          v-bind="{
            showPagination: false,
            data: options.waltonGardnerMelwinScale.scaleValues,
            columns: options.waltonGardnerMelwinScale.scaleColumn,
          }"
        />
      </div>

      <br />

      <div class="section">
        <h4>2.11 Vital Signs</h4>
        <pompe-table
          v-bind="{
            showPagination: false,
            data: vitalSigns,
            columns: options.vitalSigns.columns,
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";

import axios from "axios";

// Mixin import
import muscoskeletalMotor from "./tableHelper/muscoskeletalMotor.mixin";
import motorFunctionalActivities from "./tableHelper/motorFunctionalActivities.mixin";
import waltonGardnerMelwinScale from "./tableHelper/waltonGardnerMelwinScale.mixin";
import vitalSigns from "./tableHelper/vitalSigns.mixin";
import fatigueSeverityScale from "./tableHelper/fatigueSeverityScale.mixin";
import sixMinuteWalkTest from "./tableHelper/sixMinuteWalkTest.mixin";
import pulmonaryFunctionTest from "./tableHelper/pulmonaryFunctionTest.mixin";
import rotterdam9Scale from "./tableHelper/rotterdamScale.mixin";
import mRCScale from "./tableHelper/mRCScale.mixin";
import respiratoryAssessment from "./tableHelper/respiratoryAssessment.mixin";
import modifiedRankingScale from "./tableHelper/modifiedRanking.mixin";

export default {
  name: "assessment-pdf",
  mixins: [
    vitalSigns,
    fatigueSeverityScale,
    sixMinuteWalkTest,
    pulmonaryFunctionTest,
    rotterdam9Scale,
    mRCScale,
    muscoskeletalMotor,
    waltonGardnerMelwinScale,
    motorFunctionalActivities,
    respiratoryAssessment,
    modifiedRankingScale,
  ],
  props: {
    id: {
      type: String,
      default: null,
    },
    idx: {
      type: Number,
      default: null,
    },
    authToken: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    content: {
      pdfPage: {
        patients: [
          { definition: "Patient ID", data: null },
          { definition: "Year of Birth", data: null },
          { definition: "Gender", data: null },
        ],
        assessment: [
          { definition: "Date", data: null },
          { definition: "Status", data: null },
        ],
      },
    },
    options: {
      patients: {
        columns: [
          { key: "definition", label: "Definition" },
          { key: "data", label: "Data" },
        ],
      },
      assessment: {
        columns: [
          { key: "definition", label: "Definition" },
          { key: "data", label: "Data" },
        ],
      },
      modifiedRankingScale: {
        columns: [
          { key: "question", label: "Question" },
          { key: "data", label: "Data" },
        ],
        scaleColumn: [
          { key: "scale", label: "Scale" },
          { key: "definition", label: "Definition" },
        ],
        scaleValues: [
          { scale: 0, definition: "No symptomps at all" },
          {
            scale: 1,
            definition:
              "No Significant diability despite symptomps, able to carry out all usual duties and activities",
          },
          {
            scale: 2,
            definition:
              "Slight disability, unable to carry out all previous activities",
          },
          {
            scale: 3,
            definition:
              "Moderate disability; requiring some help, but able to walk without assistance",
          },
          {
            scale: 4,
            definition: `Moderately severe diability; unable to walk without assistance and unable to
              attend to own bodily needs without assistance`,
          },
          {
            scale: 5,
            definition: `Severe disability; bedridden, incontinent and requiring constant nursing care
                        and attention`,
          },
          {
            scale: 6,
            definition: `Dead`,
          },
        ],
      },
      respiratoryAssessment: {
        columns: [
          { key: "question", label: "Question" },
          { key: "data", label: "Data" },
        ],
      },
      muscoskeletalMotorAssessment: {
        columns: [
          { key: "question", label: "Question" },
          { key: "data", label: "Data" },
        ],
      },
      generalMedicalStatus: {
        columns: [
          { key: "question", label: "Question", width: "300" },
          { key: "data", label: "Data" },
        ],
      },
      pulmonaryFunctionTest: {
        columns: [
          { key: "question", label: "Question", width: "300" },
          { key: "data", label: "Data" },
        ],
      },
      sixMinuteWalkTest: {
        columns: [
          { key: "question", label: "Question", width: "300" },
          { key: "data", label: "Data" },
        ],
        scaleColumn: [
          { key: "scale", label: "Scale", width: "300" },
          { key: "definition", label: "Definition" },
        ],
        scaleValues: [
          { scale: "Unknown", definition: "-" },
          { scale: 0, definition: "Nothing at all" },
          { scale: 0.5, definition: "Very, very slight (just noticeable)" },
          { scale: 1, definition: "Very slight" },
          { scale: 2, definition: "Slight (light)" },
          { scale: 3, definition: "Moderate" },
          { scale: 4, definition: "Somewhat severe" },
          { scale: 5, definition: "Severe (heavy)" },
          { scale: 6, definition: "" },
          { scale: 7, definition: "Very severe" },
          { scale: 8, definition: "" },
          { scale: 9, definition: "" },
          { scale: 10, definition: "Very, very severe (maximal)" },
        ],
      },
      medicalResearchCouncilScale: {
        columns: [
          { key: "question", label: "Question" },
          { key: "data", label: "Data" },
        ],
        scaleColumn: [
          { key: "scale", label: "Scale" },
          { key: "definition", label: "Definition" },
        ],
        scaleValues: [
          { scale: 0, definition: "No palpable muscle activity" },
          {
            scale: 1,
            definition:
              "	A flicker of activity is seen or palpated in the muscle",
          },
          {
            scale: 2,
            definition:
              "Joint is moved when the effects of gravity are minimized with a position change",
          },
          {
            scale: "3-",
            definition:
              "Joint is moved against gravity but not through the full available range of motion",
          },
          {
            scale: "3",
            definition:
              "Joint is moved through the full available range of motion against gravity but cannot accept resistance",
          },
          {
            scale: "3+",
            definition: `Joint is moved against gravity and a small amount of resistance. Muscle is
                                      capable of transient resistance but collapses abruptly. This degree of
                                      weakness is not used for muscles capable of sustained resistance throughout
                                      the whole range of movement.`,
          },
          {
            scale: "4-",
            definition: `Muscle is weak but moves the joint against a combination of gravity and
                                      minimal resistance`,
          },
          {
            scale: "4",
            definition: `Muscle is weak but moves the joint against a combination of gravity and
                                    moderate resistance`,
          },
          {
            scale: "4+",
            definition: `Muscle is weak, but moves the joint against a combination of gravity and
                                      moderate-to-maximal resistance`,
          },
          { scale: "5-", definition: `Barely detectable weakness` },
          { scale: "5", definition: "Normal Strength" },
          { scale: "Unk", definition: "Unknown" },
        ],
      },
      motorFunctionalActivities: {
        columns: [
          { key: "question", label: "Question" },
          { key: "data", label: "Data" },
        ],
      },
      waltonGardnerMelwinScale: {
        columns: [
          { key: "question", label: "Question" },
          { key: "data", label: "Data" },
        ],
        scaleColumn: [
          { key: "scale", label: "Scale" },
          { key: "definition", label: "Definition" },
        ],
        scaleValues: [
          { scale: "Grade 0", definition: "Pre Clinical. All Activities" },
          {
            scale: "Grade 1",
            definition: "Walks normally, Unable to run freely",
          },
          {
            scale: "Grade 2",
            definition:
              "Detectable defect in posture or gait. Climbs stairs without using banister.",
          },
          {
            scale: "Grade 3",
            definition: "Climbs stairs only with banisters.",
          },
          {
            scale: "Grade 4",
            definition: "Walks without assistance. Unable to climb stairs",
          },
          {
            scale: "Grade 5",
            definition: "Walks without assistance. Unable to rise from chair.",
          },
          {
            scale: "Grade 6",
            definition: "Walks only with calipers or other aids.",
          },
          {
            scale: "Grade 7",
            definition:
              "Unable to walk. Sits erect in a chair. Able to roll wheelchair and eat and drink normally.",
          },
          {
            scale: "Grade 8",
            definition:
              "Sits unsupported in chair. Unable to roll wheelchair or unable to drink from a glass unassisted.",
          },
          {
            scale: "Grade 9",
            definition:
              "Unable to sit erect without support or unable to eat or drink without assistance.",
          },
          {
            scale: "Grade 10",
            definition: "Confined to bed. Requires help for all activities.",
          },
        ],
      },
      fatigueSeverityScale: {
        columns: [
          { key: "question", label: "Question" },
          { key: "data", label: "Data" },
        ],
        scaleColumn: [
          { key: "scale", label: "Scale" },
          { key: "definition", label: "Definition" },
        ],
        scaleValues: [
          { scale: "1", definition: "Completely Disagree" },
          { scale: "2", definition: "-" },
          { scale: "3", definition: "-" },
          { scale: "4", definition: "-" },
          { scale: "5", definition: "-" },
          { scale: "6", definition: "-" },
          { scale: "7", definition: "Completely Agree" },
        ],
      },
      rotterdam9Scale: {
        columns: [
          { key: "question", label: "Question" },
          { key: "data", label: "Data" },
        ],
      },
      vitalSigns: {
        columns: [
          { key: "question", label: "Question" },
          { key: "data", label: "Data" },
        ],
      },
    },
  }),
  watch: {
    authToken: {
      handler(val) {
        if (val) {
          if (this.id) {
            axios
              .get(this.$apiEndpoints.assessments + `/${this.id}`, {
                headers: {
                  Authorization: val,
                },
              })
              .then((response) => {
                this.handleGetAssessmentSuccess(response);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    exportToPDF() {
      html2pdf(this.$refs.document, {
        margin: 0.5,
        filename: `Assessment-${this.content.pdfPage.patients[0].data}.pdf`,
        html2canvas: { dpi: 200 },
        jsPDF: { unit: "in", format: "letter", orientation: "p" },
      }).set({
        pagebreak: { mode: ["avoid-all", "css", "legacy"] },
      });
    },
    handleGetAssessmentSuccess(response) {
      const { assessmentPages } = response.data;

      // get patient data
      this.getPatientData(response.data.patientId);

      // assign assessment data
      this.assignModifiedRankingScale(assessmentPages[6]);
      this.assignVitalSigns(assessmentPages[10]);
      this.assignFatigueSeverityScale(assessmentPages[8]);
      this.assignSixMinuteWalkTest(assessmentPages[0]);
      this.assignPulmonaryFunctionTest(assessmentPages[2]);
      this.assignRotterdamScale(assessmentPages[7]);
      this.assignMRCScale(assessmentPages[1]);
      this.assignMuscoskeletalMotor(assessmentPages[5]);
      this.assignMotorFunctional(assessmentPages[3]);
      this.assignWaltonGardner(assessmentPages[9]);
      this.assignRespiratory(assessmentPages[4]);
    },
    getPatientData(id) {
      axios
        .get(this.$apiEndpoints.patients + `/${id}`, {
          headers: {
            Authorization: this.authToken,
          },
        })
        .then((response) => {
          this.handleGetPatientDataSuccess(response);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleGetPatientDataSuccess(response) {
      this.content.pdfPage.patients[0].data = response.data.ref;
      this.content.pdfPage.patients[1].data = response.data.yearOfBirth;
      this.content.pdfPage.patients[2].data = response.data.patientGender;
      this.content.pdfPage.assessment[0].data = this.formatDateAbvMonth(
        response.data.dateOfLastAssessment
      );
      this.content.pdfPage.assessment[1].data = 'Signed'
    },
  },
};
</script>

<style lang="scss">
@import "../custom.scss";
.flex {
  display: flex;
  justify-content: space-between;
}
.section {
  page-break-inside: avoid;
}
</style>
