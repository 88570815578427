import { $toNormalCase } from "@/helper/globalFunction";

export default {
  name: 'respiratoryAssessment',
  data: () => ({
    respiratoryAssessment: [
      {
        question: "Date of assessment",
        data: null
      },
      {
        question: "Is the Patient Currently receiving respiratory support",
        data: null
      },
      {
        question: "Status since last assessment",
        data: null
      },
      {
        question: "Current type of support",
        data: null,
      },
      {
        question: "Duration",
        data: null,
      },
      {
        question: "Type",
        data: null,
      },
      {
        question: "Date of First Use",
        data: null,
      }
    ],
    type: {
      cpap: "CPAP",
      bipapNIPPV: "BiPAP/NIPPV",
      other: "Other",
      unknown: "Unknown"
    },
    duration: {
      "24h/day": "24h/day",
      less24hNightAndDay: "<24h (Night and Day)",
      nightOnly: "Night Only", value: "nightOnly",
      unknown: "Unknown",
    }
  }),
  methods: {
    $toNormalCase,
    assignRespiratory(data) {
      this.respiratoryAssessment[0].data = this.formatDateAbvMonth(data.dateOfRespiratoryAssessment);
      this.respiratoryAssessment[6].data = this.formatDateAbvMonth(data.dateOfFirstUse);
      this.respiratoryAssessment[2].data = this.$toNormalCase(data.statusSinceLastAssessment);
      this.respiratoryAssessment[1].data = this.$toNormalCase(data.receivingRespiratorySupport);

      if (data.currentTypeOfSupport) {
        data.currentTypeOfSupport = data.currentTypeOfSupport.map(el => this.$toNormalCase(el));
      }
      this.respiratoryAssessment[3].data = data.currentTypeOfSupport ? data.currentTypeOfSupport.toString() : null;
      this.respiratoryAssessment[4].data = this.duration[data.respiratoryAssessmentDuration];
      this.respiratoryAssessment[5].data = this.type[data.respiratoryAssessmentType];

      if (data.currentTypeOfSupport.isArray()) {
        this.respiratoryAssessment[3].data = this.content.pdfPage.respiratoryAssessment[3].data.replaceAll(',', ', ');
      }
    }
  }
}