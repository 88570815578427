var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"assessment-pdf-template"}},[_c('div',{staticClass:"flex mt-3"},[_vm._m(0),_c('div',{staticClass:"right"},[_c('button',{staticClass:"btn btn-danger btn-sm",on:{"click":_vm.exportToPDF}},[_vm._v(" Generate PDF ")])])]),_c('hr'),_c('div',{ref:"document",staticClass:"container"},[(_vm.idx === 0)?_c('h6',[_vm._v("Swiss Pompe Registry")]):_vm._e(),_c('hr'),_c('div',{staticClass:"section"},[_c('h4',[_vm._v("1. Patient")]),_c('pompe-table',_vm._b({},'pompe-table',{
          showPagination: false,
          data: _vm.content.pdfPage.patients,
          columns: _vm.options.patients.columns,
        },false))],1),_c('br'),_c('div',{staticClass:"section"},[_c('h4',[_vm._v("2. Assessment")]),_c('pompe-table',_vm._b({},'pompe-table',{
          showPagination: false,
          data: _vm.content.pdfPage.assessment,
          columns: _vm.options.assessment.columns,
        },false))],1),_c('br'),_c('div',{staticClass:"section"},[_c('h4',[_vm._v("2.1 Six Minute Walk Test")]),_c('pompe-table',_vm._b({},'pompe-table',{
          showPagination: false,
          data: _vm.sixMinuteWalkTest,
          columns: _vm.options.sixMinuteWalkTest.columns,
        },false))],1),_c('div',{staticClass:"section"},[_c('h6',{staticClass:"font-weight-light scale-title"},[_vm._v("Borg Scale")]),_c('pompe-table',_vm._b({},'pompe-table',{
          showPagination: false,
          data: _vm.options.sixMinuteWalkTest.scaleValues,
          columns: _vm.options.sixMinuteWalkTest.scaleColumn,
        },false))],1),_c('br'),_c('div',{staticClass:"section"},[_c('h4',[_vm._v("2.2 Medical Research Council Scale")]),_c('pompe-table',_vm._b({},'pompe-table',{
          showPagination: false,
          data: _vm.mRCScale,
          columns: _vm.options.medicalResearchCouncilScale.columns,
        },false))],1),_c('div',{staticClass:"section"},[_c('h6',{staticClass:"font-weight-light scale-title"},[_vm._v("MRC Scale")]),_c('pompe-table',_vm._b({},'pompe-table',{
          showPagination: false,
          data: _vm.options.medicalResearchCouncilScale.scaleValues,
          columns: _vm.options.medicalResearchCouncilScale.scaleColumn,
        },false))],1),_c('br'),_c('div',{staticClass:"section"},[_c('h4',[_vm._v("2.3 Pulmonary Function Test")]),_c('pompe-table',_vm._b({},'pompe-table',{
          showPagination: false,
          data: _vm.pulmonaryFunctionTest,
          columns: _vm.options.pulmonaryFunctionTest.columns,
        },false))],1),_c('br'),_c('div',{staticClass:"section"},[_c('h4',[_vm._v("2.4 Motor Functional Activities")]),_c('pompe-table',_vm._b({},'pompe-table',{
          showPagination: false,
          data: _vm.motorFunctional,
          columns: _vm.options.motorFunctionalActivities.columns,
        },false))],1),_c('br'),_c('div',{staticClass:"section"},[_c('h4',[_vm._v("2.5 Respiratory Assessment")]),_c('pompe-table',_vm._b({},'pompe-table',{
          showPagination: false,
          data: _vm.respiratoryAssessment,
          columns: _vm.options.respiratoryAssessment.columns,
        },false))],1),_c('br'),_c('div',{staticClass:"section"},[_c('h4',[_vm._v("2.6 Muscoskeletal / Motor Assessment")]),_c('pompe-table',_vm._b({},'pompe-table',{
          showPagination: false,
          data: _vm.muscoskeletalMotor,
          columns: _vm.options.muscoskeletalMotorAssessment.columns,
        },false))],1),_c('br'),_c('div',{staticClass:"section"},[_c('h4',[_vm._v("2.7 Modified Ranking Scale")]),_c('pompe-table',_vm._b({},'pompe-table',{
          showPagination: false,
          data: _vm.modifiedRankingScale,
          columns: _vm.options.modifiedRankingScale.columns,
        },false))],1),_c('div',{staticClass:"section"},[_c('h6',{staticClass:"font-weight-light scale-title"},[_vm._v("Modified Ranking Scale")]),_c('pompe-table',_vm._b({},'pompe-table',{
          showPagination: false,
          data: _vm.options.modifiedRankingScale.scaleValues,
          columns: _vm.options.modifiedRankingScale.scaleColumn,
        },false))],1),_c('br'),_c('div',{staticClass:"section"},[_c('h4',[_vm._v("2.8 Rotterdam 9-Item Handicap Scale")]),_c('pompe-table',_vm._b({},'pompe-table',{
          showPagination: false,
          data: _vm.rotterdamScale,
          columns: _vm.options.rotterdam9Scale.columns,
        },false))],1),_c('br'),_c('div',{staticClass:"section"},[_c('h4',[_vm._v("2.9 Fatigue Severity Scale")]),_c('pompe-table',_vm._b({},'pompe-table',{
          showPagination: false,
          data: _vm.fatigueSeverityScale,
          columns: _vm.options.fatigueSeverityScale.columns,
        },false))],1),_c('div',{staticClass:"section"},[_c('h6',{staticClass:"font-weight-light scale-title"},[_vm._v("Fatigue Severity Scale")]),_c('pompe-table',_vm._b({},'pompe-table',{
          showPagination: false,
          data: _vm.options.fatigueSeverityScale.scaleValues,
          columns: _vm.options.fatigueSeverityScale.scaleColumn,
        },false))],1),_c('br'),_c('div',{staticClass:"section"},[_c('h4',[_vm._v("2.10 Walton & Gardner Melwin Scale")]),_c('pompe-table',_vm._b({},'pompe-table',{
          showPagination: false,
          data: _vm.waltonGardner,
          columns: _vm.options.waltonGardnerMelwinScale.columns,
        },false))],1),_c('div',{staticClass:"section"},[_c('h6',{staticClass:"font-weight-light scale-title"},[_vm._v(" Walton & Gardner Melwin Scale ")]),_c('pompe-table',_vm._b({},'pompe-table',{
          showPagination: false,
          data: _vm.options.waltonGardnerMelwinScale.scaleValues,
          columns: _vm.options.waltonGardnerMelwinScale.scaleColumn,
        },false))],1),_c('br'),_c('div',{staticClass:"section"},[_c('h4',[_vm._v("2.11 Vital Signs")]),_c('pompe-table',_vm._b({},'pompe-table',{
          showPagination: false,
          data: _vm.vitalSigns,
          columns: _vm.options.vitalSigns.columns,
        },false))],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"left"},[_c('h4',{staticClass:"mt-2 text-muted"},[_vm._v("PDF Preview")])])}]

export { render, staticRenderFns }