export default {
  name: 'pulmonaryFunctionTestMixin',
  data: () => ({
    pulmonaryFunctionTest: [
      {
        question: 'Date of Pulmonary Function Test',
        data: null
      },
      {
        question: 'FVC (upright) (L)',
        data: null
      },
      {
        question: 'FVC (upright) % predicted normal',
        data: null
      },
      {
        question: 'FEV1 (upright) (L)',
        data: null,
      },
      {
        question: 'FEV1 (upright) % predicted normal',
        data: null,
      },
      {
        question: 'FVC (supine) (L)',
        data: null,
      },
      {
        question: 'FVC (supine) % predicted normal',
        data: null,
      },
      {
        question: 'FEV1 (supine) (L)',
        data: null,
      },
      {
        question: 'FEV1 (supine) % predicted normal',
        data: null
      },
      {
        question: 'MIP (PImax)',
        data: null,
      },
      {
        question: 'MIP Unit',
        data: null,
      },
      {
        question: 'SNIP (upright)',
        data: null,
      },
      {
        question: 'SNIP (upright) Unit',
        data: null,
      },
      {
        question: 'SNIP (supine)',
        data: null,
      },
      {
        question: 'SNIP (supine) Unit',
        data: null,
      },
      {
        question: 'VC (upright) (L)',
        data: null,
      },
      {
        question: 'VC (supine) (L)',
        data: null,
      },
      {
        question: 'Notes',
        data: null,
      }
    ]
  }),
  methods: {
    assignPulmonaryFunctionTest(data) {
      this.pulmonaryFunctionTest[0].data = this.formatDateAbvMonth(data.dateOfPulmonaryFunctionTest);
      this.pulmonaryFunctionTest[1].data = data.fVCUprightL;
      this.pulmonaryFunctionTest[2].data = data.fVCUprightPredictedNormal;
      this.pulmonaryFunctionTest[3].data = data.fEV1UprightL;
      this.pulmonaryFunctionTest[4].data = data.fEV1UprightPredictedNormal;
      this.pulmonaryFunctionTest[5].data = data.fVCSupineL;
      this.pulmonaryFunctionTest[6].data = data.fVCSupinePredictedNormal;
      this.pulmonaryFunctionTest[7].data = data.fEV1SupineL;
      this.pulmonaryFunctionTest[8].data = data.fEV1SupinePredictedNormal;
      this.pulmonaryFunctionTest[9].data = data.mIPPImax;
      this.pulmonaryFunctionTest[10].data = data.mIPPImaxUnit;
      this.pulmonaryFunctionTest[11].data = data.sNIPUpright;
      this.pulmonaryFunctionTest[12].data = data.sNIPUprightUnit;
      this.pulmonaryFunctionTest[13].data = data.sNIPSupine;
      this.pulmonaryFunctionTest[14].data = data.sNIPSupineUnit;
      this.pulmonaryFunctionTest[15].data = data.vCUprightL;
      this.pulmonaryFunctionTest[16].data = data.vCSupineL;
      this.pulmonaryFunctionTest[17].data = 
        data.comments?.length > 1 && data.comments[0] !== '' ? data.comments.toString() : "-";
    }
  }
};
