export default {
  name: 'rotterdamScaleMixin',
  data: () => ({
    rotterdamScale: [
      {
        question: '1. Mobility indoors: Are you able to move room to room, negotiating doors, carpets and polished surfaces',
        data: null
      },
      {
        question: '2. Mobility outdoors: Are you able to move outdoors from one place to another, negotiating curbs and uneven grounds',
        data: null
      },
      {
        question: `3. Kitchen tasks: Are you able to fulfill tasks such as
                  making a pot of tea/coffee and serving it; are you
                  able to collect items from a high and low cupboard,
                  refrigerator, etc.? (Other kitchen tasks are also
                  applicable.)`,
        data: null
      },
      {
        question: `4. Domestic tasks (indoors): Are you able to fulfill
                  house-cleaning tasks, such as vacuum cleaning,
                  dishwashing, doing the laundry, dusting, etc.?`,
        data: null,
      },
      {
        question: `5. Domestic tasks (outdoors): Are you able to do the
                  shopping, manage the garden, clean the car, etc.`,
        data: null,
      },
      {
        question: `6. Leisure activities (indoors): Are you able to read a
                  newspaper/magazine or a book, use the telephone,
                  fulfill a hobby (other than sporting)`,
        data: null,
      },
      {
        question: `7. Leisure activities (outdoors): Are you able to go to
                  a party, theater, movies, concerts, museums,
                  meetings, participate in sport`,
        data: null,
      },
      {
        question: `8. Able to drive a car/go by bus/ride a bicycle: Are
                  you able to drive a car, go on a bus/subway, or ride a
                  bicycle`,
        data: null,
      },
      {
        question: `9. Work/study: Are you able to fulfill your prior (before
                  becoming ill) job/study`,
        data: null,
      }
    ]
  }),
  methods: {
    assignRotterdamScale(data) {
      this.rotterdamScale[0].data = data.mobilityIndoors;
      this.rotterdamScale[1].data = data.mobilityOutdoors;
      this.rotterdamScale[2].data = data.kitchenTasks;
      this.rotterdamScale[3].data = data.domesticTasksIndoors;
      this.rotterdamScale[4].data = data.domesticTasksOutdoors;
      this.rotterdamScale[5].data = data.leisureActivitiesIndoors;
      this.rotterdamScale[6].data = data.leisureActivitiesOutdoors;
      this.rotterdamScale[7].data = data.ableToDriveACarGoByBusRideABicycle;
      this.rotterdamScale[8].data = data.workStudy;
    }
  }
};
