import { $toNormalCase } from "@/helper/globalFunction";
export default {
  name: 'muscoskeletalMotorMixin',
  data: () => ({
    muscoskeletalMotor: [
      { question: "Date of Assessment", data: null },
      {
        question: "Proximal muscle weakness in upper extremities",
        data: null
      },
      {
        question: "Status since last assessment",
        data: null
      },
      {
        question: "Distal muscle weakness in upper extremities ",
        data: null
      },
      {
        question: "Status since the last assessment",
        data: null,
      },
      {
        question: "Proximal muscle weakness in lower extremities",
        data: null,
      },
      {
        question: "Status since the last assessment",
        data: null,
      },
      {
        question: "Distal muscle weakness in lower extremities",
        data: null
      },
      {
        question: "Status since the last assessment",
        data: null,
      },
      {
        question: "Muscle weakness in trunk",
        data: null
      },
      {
        question: "Status since the last assessment",
        data: null,
      },
      {
        question: "Is the patient currently ambulatory",
        data: null,
      },
      {
        question: "Ability",
        data: null
      },
      {
        question: "If ambulatory with difficulty",
        data: null
      },
      {
        question: "Currently using ambulation devices",
        data: null,
      },
      {
        question: "Status since the last assessment",
        data: null,
      },
      {
        question: "Type of support",
        data: null
      }
    ]
  }),
  methods: {
    $toNormalCase,
    assignMuscoskeletalMotor(data) {
      this.muscoskeletalMotor[0].data = 
        this.formatDateAbvMonth(data.dateOfmuscoskeletalMotorAssessment);
      this.muscoskeletalMotor[1].data = 
        this.$toNormalCase(data.proximalWeaknessInUpperExtremities.isPresent);
      this.muscoskeletalMotor[2].data = 
        this.$toNormalCase(data.proximalWeaknessInUpperExtremities.theStatus);
      this.muscoskeletalMotor[3].data = 
        this.$toNormalCase(data.distalWeaknessInUpperExtremities.isPresent);
      this.muscoskeletalMotor[4].data = 
        this.$toNormalCase(data.distalWeaknessInUpperExtremities.theStatus);
      this.muscoskeletalMotor[5].data = 
        this.$toNormalCase(data.proximalWeaknessInLowerExtremities.isPresent);
      this.muscoskeletalMotor[6].data = 
        this.$toNormalCase(data.proximalWeaknessInLowerExtremities.theStatus);
      this.muscoskeletalMotor[7].data = 
        this.$toNormalCase(data.distalWeaknessInLowerExtremities.isPresent);
      this.muscoskeletalMotor[8].data = 
        this.$toNormalCase(data.distalWeaknessInLowerExtremities.theStatus);
      this.muscoskeletalMotor[9].data = 
        this.$toNormalCase(data.weaknessInTrunk.isPresent);
      this.muscoskeletalMotor[10].data = 
        this.$toNormalCase(data.weaknessInTrunk.theStatus);
      this.muscoskeletalMotor[11].data = 
        this.$toNormalCase(data.patientCurrentlyInAmbulatory);
      this.muscoskeletalMotor[14].data = 
        this.$toNormalCase(data.currentlyUsingAmbulatoryDevice);
      this.muscoskeletalMotor[15].data = 
        this.$toNormalCase(data.patientAmbulatoryStatus);
      this.muscoskeletalMotor[16].data = 
        this.$toNormalCase(data.ambulatoryDevice)
    }
  }
};