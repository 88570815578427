export default {
  name: 'mRCScaleMixin',
  data: () => ({
    mRCScale: [
      {
        question: 'Exam Date',
        data: null
      },
      {
        question: 'Shoulder abduction',
        data: null
      },
      {
        question: 'Elbow flexion',
        data: null
      },
      {
        question: 'Wrist flexion',
        data: null,
      },
      {
        question: 'Wrist extenstion',
        data: null,
      },
      {
        question: 'Thumb abduction',
        data: null,
      },
      {
        question: 'Hip flexion',
        data: null,
      },
      {
        question: 'Knee extension',
        data: null,
      },
      {
        question: 'Ankle dorsiflexion',
        data: null
      },
      {
        question: 'Ankle eversion',
        data: null,
      },
      {
        question: 'Ankle inversion',
        data: null,
      },
      {
        question: 'Neck extension',
        data: null,
      },
      {
        question: 'Shoulder external rotation',
        data: null,
      },
      {
        question: 'Knee flexion',
        data: null,
      },
      {
        question: 'Ankle plantar flexion',
        data: null,
      },
      {
        question: 'Hip extension',
        data: null,
      },
      {
        question: 'Hip abduction',
        data: null,
      },
      {
        question: 'Hip adduction',
        data: null,
      },
      {
        question: 'Elbow extension',
        data: null,
      },
      {
        question: 'Neck flexion',
        data: null,
      }
    ]
  }),
  methods: {
    assignMRCScale(data) {
      this.mRCScale[0].data = this.formatDateAbvMonth(data.dateOfMRCScale);
      this.mRCScale[1].data = 
        `Left: ${data.shoulderAbductionLeft || '-'}, Right: ${data.shoulderAbductionRight || '-'}`;
      this.mRCScale[2].data = 
        `Left: ${data.elbowFlexionLeft || '-'}, Right: ${data.elbowFlexionRight || '-'}`;
      this.mRCScale[3].data =
        `Left: ${data.wristFlexionLeft|| '-'}, Right: ${data.wristFlexionRight|| '-'}`;
      this.mRCScale[4].data = 
        `Left: ${data.wristExtensionLeft || '-'}, Right: ${data.wristExtensionRight || '-'}`;
      this.mRCScale[5].data =
        `Left: ${data.thumbAbductionLeft || '-'}, Right: ${data.thumbAbductionRight || '-'}`;
      this.mRCScale[6].data =
        `Left: ${data.hipFlexionLeft || '-'}, Right: ${data.hipFlexionRight || '-'}`;
      this.mRCScale[7].data =
        `Left: ${data.kneeExtensionLeft || '-'}, Right: ${data.kneeExtensionRight || '-'}`;
      this.mRCScale[8].data =
        `Left: ${data.ankleDorsiflexionLeft || '-'}, Right: ${data.ankleDorsiflexionRight || '-'}`;
      this.mRCScale[9].data =
        `Left: ${data.ankleEversionLeft || '-'}, Right: ${data.ankleEversionRight || '-'}`;
      this.mRCScale[10].data =
        `Left: ${data.ankleInversionLeft || '-'}, Right: ${data.ankleInversionRight || '-'}`;
      this.mRCScale[11].data = data.neckExtension || '-';
      this.mRCScale[12].data =
        `Left: ${data.shoulderExternalRotationLeft || '-'}, Right: ${data.shoulderExternalRotationRight || '-'}`;
      this.mRCScale[13].data =
        `Left: ${data.shoulderExternalRotationLeft || '-'}, Right: ${data.shoulderExternalRotationRight || '-'}`;
      this.mRCScale[14].data =
        `Left: ${data.kneeFlexionLeft || '-'}, Right: ${data.kneeFlexionRight || '-'}`;
      this.mRCScale[15].data =
        `Left: ${data.hipExtensionLeft || '-'}, Right: ${data.hipExtensionRight || '-'}`;
      this.mRCScale[16].data =
        `Left: ${data.hipAbductionLeft || '-'}, Right: ${data.hipAbductionRight || '-'}`;
      this.mRCScale[17].data =
        `Left: ${data.hipAdductionLeft || '-'}, Right: ${data.hipAdductionRight || '-'}`;
      this.mRCScale[18].data =
        `Left: ${data.elbowExtensionLeft || '-'}, Right: ${data.elbowExtensionRight || '-'}`;
      this.mRCScale[19].data = data.neckFlexion || '-';
    }
  }
};
