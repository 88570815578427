export default {
  name: 'fatigueSeverityScaleMixin',
  data: () => ({
    fatigueSeverityScale: [
      {
        question: '1. My motivation is lower when I am fatigued',
        data: null
      },
      {
        question: '2. Exercise brings on my fatigue',
        data: null
      },
      {
        question: '3. I am easily fatigued ',
        data: null
      },
      {
        question: '4. Fatigue interferes with my physical functioning',
        data: null,
      },
      {
        question: '5. Fatigue causes frequent problems for me',
        data: null,
      },
      {
        question: '6. My fatigue prevents sustained physical functioning',
        data: null,
      },
      {
        question: '7. Fatigue interferes with carrying out certain duties and responsibilities',
        data: null,
      },
      {
        question: '8. Fatigue is among my 3 most disabling symptoms',
        data: null,
      },
      {
        question: '9. Fatigue interferes with my work, family, or social life',
        data: null
      },
      {
        question: 'Exam Date',
        data: null,
      }
    ]
  }),
  methods: {
    assignFatigueSeverityScale(data) {
      this.fatigueSeverityScale[0].data = data.motivationIsLowerWhenFatigued;
      this.fatigueSeverityScale[1].data = data.exerciseBringsOnFatigue;
      this.fatigueSeverityScale[2].data = data.easilyFatigued;
      this.fatigueSeverityScale[3].data = data.fatigueInterferesWithPhysicalFunctioning;
      this.fatigueSeverityScale[4].data = data.fatigueCausesFrequentProblems;
      this.fatigueSeverityScale[5].data = data.fatiguePreventsSustainedPhysicalFunctioning;
      this.fatigueSeverityScale[6].data = data.fatigueInterferesWithCarryingOuCertainDuties;
      this.fatigueSeverityScale[7].data = data.fatigueIsAmong3MostDisablingSymptoms;
      this.fatigueSeverityScale[8].data = data.fatigueInterferesWithWork;
      this.fatigueSeverityScale[9].data = this.formatDateAbvMonth(data.dateOfFatigueSeverityScale);
    }
  }
};
