export default {
  name: 'modifiedRankingScaleMixin',
  data: () => ({
    modifiedRankingScale: [
      {
        question: "Date of assessment",
        data: null
      },
      {
        question: "Modified Rankin Scale",
        data: null
      }
    ]
  }),
  methods: {
    assignModifiedRankingScale(data) {
      this.modifiedRankingScale[0].data = this.formatDateAbvMonth(data.dateOfModifiedRankingScale);
      this.modifiedRankingScale[1].data = data.modifiedRankingScale;
    },
  }
}