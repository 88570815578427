export default {
  name: 'waltonGardnerMelwinScale',
  data: () => ({
    waltonGardner: [
      {
        question: 'Exam Date',
        data: null
      },
      {
        question: 'Grade',
        data: null
      }
    ]
  }),
  methods: {
    assignWaltonGardner(data) {
      this.waltonGardner[0].data = this.formatDateAbvMonth(data.dateOfWaltonGardnerMewinScale);
      this.waltonGardner[1].data = data.waltonGardnerMewinScaleGrade;
    }
  }
};