<template>
  <div v-if="domainIds.length" id="pdf-container">
    <pdf-index
      v-for="(id, idx) in domainIds"
      :key="id"
      :id="id"
      :idx="idx"
      :authToken="authToken"
    />
  </div>
</template>

<script>
import axios from "axios";

import PdfIndex from "./Index.vue";

export default {
  name: "PdfContainer",
  components: {
    "pdf-index": PdfIndex
  },
  data: () => ({
    domainIds: [],
    authToken: null,
    assessments: [],
  }),
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      axios
        .get(this.$route.query.url)
        .then(response => {
          this.domainIds = [...response.data.domainIds];
          this.authToken = response.data.token;

          if (this.domainIds.length === 3) {
            this.constructData();
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    async constructData() {
      axios.post(this.$apiEndpoints.assessments + "/search", {
        paging: { page: 0, size: 3 },
        sorting: [{ field: "creationDate", dir: "ASC" }],
        criteria: {
          field: "id",
          op: "in",
          value: `[${this.domainIds.toString()}]`
        }
      }, {
        headers: {
          'Authorization': this.authToken
        }
      }).then(response => {
        this.constructColumn(response.data.content);
      }).catch(error => {
        console.error(error);
      });
    }
  }
};
</script>

<style></style>
